
import Cookies from 'js-cookie';
export default function () {
    jQuery(document).ready(function ($) {
    
      const popupWrapper = $('.popup__wrapper');
      const popupButtons = $('.popup__buttons > .button');
      const popupClose   = $('.popup__closeButton');

      let poputOpenDelay = popupWrapper.attr('data-delay');
      let popupCookieExpire = popupWrapper.attr('data-expire');
      let popupUniName = popupWrapper.attr('data-uni-name');
  
      poputOpenDelay = poputOpenDelay*1000;
      if(!Cookies.get('ConcordiaPopup') || Cookies.get('ConcordiaPopup') != popupUniName){
        setTimeout(function(){
          (popupWrapper.hasClass('active')) ? popupWrapper : popupWrapper.addClass('active');
        }, poputOpenDelay); 
      }

      setEvent();

      function setEvent() {
        popupButtons.on('click', function(e) {
          e.preventDefault();
          if(Cookies.get('ConcordiaPopup')) {
            Cookies.remove('ConcordiaPopup');
          }
          Cookies.set('ConcordiaPopup', popupUniName, { expires: parseInt(popupCookieExpire) });
          location.href = $(this).attr('href');
        })

        popupClose.on('click', function(e) {
          e.preventDefault();
          if(Cookies.get('ConcordiaPopup')) {
            Cookies.remove('ConcordiaPopup');
          }
          Cookies.set('ConcordiaPopup', popupUniName, { expires: parseInt(popupCookieExpire) });
          (popupWrapper.hasClass('active')) ? popupWrapper.removeClass('active') : popupWrapper.addClass('active');
        })
      } 
        
    });
  } 