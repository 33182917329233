export default function () {
  jQuery(document).ready(function ($) {
    if ($('#banner-slider').hasClass('slick-initialized')) {
      $('#banner-slider').slick('refresh');
    } else {
      $('#banner-slider').slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false
      });
    }
  });
}